// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-book-jsx": () => import("./../../../src/pages/book.jsx" /* webpackChunkName: "component---src-pages-book-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-success-jsx": () => import("./../../../src/pages/contact-success.jsx" /* webpackChunkName: "component---src-pages-contact-success-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-newsletter-success-jsx": () => import("./../../../src/pages/newsletter-success.jsx" /* webpackChunkName: "component---src-pages-newsletter-success-jsx" */),
  "component---src-pages-policies-booking-cancellation-jsx": () => import("./../../../src/pages/policies/booking-cancellation.jsx" /* webpackChunkName: "component---src-pages-policies-booking-cancellation-jsx" */),
  "component---src-pages-policies-privacy-jsx": () => import("./../../../src/pages/policies/privacy.jsx" /* webpackChunkName: "component---src-pages-policies-privacy-jsx" */),
  "component---src-pages-policies-terms-conditions-jsx": () => import("./../../../src/pages/policies/terms-conditions.jsx" /* webpackChunkName: "component---src-pages-policies-terms-conditions-jsx" */),
  "component---src-pages-treatments-brows-jsx": () => import("./../../../src/pages/treatments/brows.jsx" /* webpackChunkName: "component---src-pages-treatments-brows-jsx" */),
  "component---src-pages-treatments-face-jsx": () => import("./../../../src/pages/treatments/face.jsx" /* webpackChunkName: "component---src-pages-treatments-face-jsx" */),
  "component---src-pages-treatments-jsx": () => import("./../../../src/pages/treatments.jsx" /* webpackChunkName: "component---src-pages-treatments-jsx" */),
  "component---src-pages-treatments-lashes-jsx": () => import("./../../../src/pages/treatments/lashes.jsx" /* webpackChunkName: "component---src-pages-treatments-lashes-jsx" */),
  "component---src-templates-blogpost-jsx": () => import("./../../../src/templates/blogpost.jsx" /* webpackChunkName: "component---src-templates-blogpost-jsx" */)
}

